var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"musicbox"},[_c('div',{staticClass:"musiclist"},[_c('div',{staticStyle:{"width":"320px"}}),_c('ul',_vm._l((_vm.musicList),function(item,index){return _c('li',{key:item.id,class:{ active: item.play },on:{"click":function($event){return _vm.playMusic(item)}}},[_c('span',{staticClass:"number"},[(!item.play)?_c('span',[_vm._v(_vm._s(index + 1))]):_vm._e(),(item.play)?_c('img',{attrs:{"width":"14","src":require("@/assets/images/playing.gif")}}):_vm._e()]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),(_vm.total > _vm.pageCount)?_c('div',{staticStyle:{"position":"absolute","bottom":"50px"}},[_c('el-pagination',{attrs:{"layout":"prev, pager, next","total":_vm.total,"small":true,"page-size":_vm.pageCount,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"current-change":function($event){return _vm.goto()}}})],1):_vm._e()]),(_vm.curMusic)?_c('div',{staticClass:"player"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.curMusic.name)+" ")]),_c('div',{staticClass:"changpian"},[_c('div',{staticClass:"needle",style:({ transform: 'rotate(' + (_vm.curMusic.play ? 0 : -30) + 'deg)' })},[_c('img',{attrs:{"width":"74px","height":"120px","src":require("@/assets/images/needle-ab.png")}})]),_c('div',{staticClass:"pan",style:({ 'animation-name': _vm.curMusic.play ? 'rotate' : 'static' })},[_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"controller"},[_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":_vm.previous}},[_c('i',{staticClass:"el-icon-arrow-left"})]),_c('button',[_c('i',{class:{
              'el-icon-video-play': !_vm.curMusic.play,
              'el-icon-video-pause': _vm.curMusic.play,
            },on:{"click":_vm.pauseOrPlay}})]),_c('button',{on:{"click":_vm.next}},[_c('i',{staticClass:"el-icon-arrow-right"})]),_c('button',[_c('i',{class:{
              'el-icon-refresh-right': _vm.playMode === 1,
              'el-icon-refresh': _vm.playMode === 0,
            },on:{"click":function($event){_vm.playMode = _vm.playMode === 0 ? 1 : 0}}})])]),_c('div',{staticClass:"progress"},[_c('el-progress',{staticClass:"bar",attrs:{"percentage":(_vm.currentTime / _vm.duration) * 100,"show-text":false}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatTime(_vm.currentTime))+"/"+_vm._s(_vm.formatTime(_vm.duration)))])],1)])]):_vm._e(),(!_vm.curMusic)?_c('div',{staticClass:"player"},[_c('div',{staticClass:"title",staticStyle:{"font-size":"30px","margin-top":"40px"}},[_vm._v(" 听音乐，上【51文件】 ")]),_vm._m(2)]):_vm._e(),(_vm.curMusic)?_c('div',{staticClass:"mobile-controller"},[_c('div',{staticClass:"pan",style:({ 'animation-name': _vm.curMusic.play ? 'rotate' : 'static' })},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"controller"},[_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":_vm.previous}},[_c('i',{staticClass:"el-icon-arrow-left"})]),_c('button',[_c('i',{class:{
              'el-icon-video-play': !_vm.curMusic.play,
              'el-icon-video-pause': _vm.curMusic.play,
            },on:{"click":_vm.pauseOrPlay}})]),_c('button',{on:{"click":_vm.next}},[_c('i',{staticClass:"el-icon-arrow-right"})]),_c('button',[_c('i',{class:{
              'el-icon-refresh-right': _vm.playMode === 1,
              'el-icon-refresh': _vm.playMode === 0,
            },on:{"click":function($event){_vm.playMode = _vm.playMode === 0 ? 1 : 0}}})])]),_c('div',{staticClass:"progress"},[_c('el-progress',{staticClass:"bar",attrs:{"percentage":(_vm.currentTime / _vm.duration) * 100,"show-text":false}}),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.formatTime(_vm.currentTime))+"/"+_vm._s(_vm.formatTime(_vm.duration)))])],1)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cover"},[_c('img',{attrs:{"width":"120","height":"120","src":require("@/assets/logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"singlecover"},[_c('img',{attrs:{"width":"180","height":"180","src":require("@/assets/images/singlecover.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","margin-top":"30px"}},[_c('img',{attrs:{"width":"180","src":require("@/assets/logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cover"},[_c('img',{attrs:{"width":"50","height":"50","src":require("@/assets/logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"singlecover"},[_c('img',{attrs:{"width":"80","height":"80","src":require("@/assets/images/singlecover.png")}})])}]

export { render, staticRenderFns }